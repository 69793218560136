import RichText from '../components/RichText'
import { ContactFormSectionFragment } from '../graphql/contactFormSectionFragment.generated'
import ContactFormSection from '../sections/ContactFormSection'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: ContactFormSectionFragment
}

const ContactFormSectionBlock = ({
  content: { form, image, title, contacts, addressInfo, followUs, socialLinks },
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()

  if (!title || !followUs || !form) {
    return null
  }

  return (
    <ContactFormSection
      form={
        form.map(
          ({
            id,
            formTitle,
            text,
            formFields,
            recaptcha,
            privacyPolicy,
            submitText,
            succesText,
            errorText,
          }) => ({
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            key: id!,
            formTitle: formTitle!,
            text: <RichText text={text} />,
            formFields:
              formFields.map((field) =>
                field.__typename === 'FormFieldTextRecord'
                  ? {
                      id: field.id!,
                      label: field.label!,
                      variation: field.variation!,
                      name: field.name!,
                      placeholder: field.placeholder!,
                      pattern: field.pattern || undefined,
                      required: field.required || undefined,
                      errorMessage: field.errorMessage || undefined,
                      dependentFieldName: field.dependentFieldName || undefined,
                      dependentFieldValue:
                        field.dependentFieldValue || undefined,
                    }
                  : {
                      id: field.id!,
                      label: field.label!,
                      options: field.options!.split('\n'),
                      name: field.name!,
                      placeholder: field.placeholder!,
                      required: field.required || undefined,
                      errorMessage: field.errorMessage || undefined,
                    },
              ) || undefined,
            recaptcha: <RichText text={recaptcha!} />,
            privacyPolicy: <RichText text={privacyPolicy!} />,
            submitText: submitText!,
            succesText: <RichText text={succesText!} />,
            errorText: <RichText text={errorText!} />,
          }),
        )[0]
      }
      image={convertDatoImage(image)}
      title={title}
      contacts={contacts.map((contact) => ({
        key: contact.id!,
        name: contact.name!,
        email: contact.email!,
        phone: contact.phone || undefined,
      }))}
      addressInfo={addressInfo.map((address) => ({
        id: address.id,
        title: address.title || undefined,
        streetname: address.streetname!,
        housenumber: address.housenumber!,
        postcode: address.postcode!,
        city: address.city!,
        googleLink: address.googleLink || undefined,
      }))}
      followUs={followUs}
      socialLinks={socialLinks.map(({ icon, link, id }) => ({
        id,
        ...resolveLink(siteConfig, link[0]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        icon: icon!,
      }))}
      {...others}
    />
  )
}

export default ContactFormSectionBlock
