import { css } from '@emotion/react'
import { ReactNode, useState } from 'react'
import styled from '@emotion/styled'
import Image from '../components/Image'
import ContactFormActionCard from '../components/ContactFormActionCard'

export const Section = styled.section(({ theme }) => [
  css`
    background-color: ${theme.colors.lightBlue};
    padding-bottom: ${theme.spacing.x14}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px ${theme.spacing.x14}px;
    }

    .show {
      position: relative;
      opacity: 1;
      pointer-events: all;
    }
    .hide {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
  `,
])

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    max-width: ${theme.spacing.gridMaxWidth}px;
    gap: ${theme.spacing.x4}px;
    padding: ${theme.spacing.x5}px ${theme.spacing.x2}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x5}px 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px 0;
    }
  `,
)

const Row = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
    }
  `,
)

const LeftColumn = styled.div(css`
  display: flex;
  flex-flow: column;
  max-width: 542px;
`)

const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    color: ${theme.colors.primaryBlue};
    margin-bottom: ${theme.spacing.x2}px;
  `,
)

const Text = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x3}px;
  `,
)

const RightColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    position: relative;
    width: 100%;

    ::after {
      content: '';
      position: absolute;
      left: ${theme.spacing.x20}px;
      top: ${theme.spacing.x6}px;
      width: 100%;
      height: 420px;
      background: ${theme.colors.greenLight};
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      flex-flow: row;
      padding-top: ${theme.spacing.x20}px;
    }
  `,
)

const StyledImage = styled(Image)(
  ({ theme }) => css`
    width: 100%;
    z-index: ${theme.zIndex.relative4};

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      position: absolute;
      top: -${theme.spacing.x13}px;
      right: 0;
      width: 551px;
      height: auto;
    }
  `,
)

const CardContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2}px;
    padding-bottom: ${theme.spacing.x10}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x3}px;
    }
  `,
)

interface Props {
  title: string
  text: ReactNode
  image?: ImageInfo
  actions?: Array<
    | {
        type: 'LinkAction'
        id: string
        title: string
        description: string
        link?: {
          href: string
          target: '_blank' | undefined
        }
      }
    | {
        type: 'FormAction'
        id: string
        title: string
        description: string
        form: ReactNode
      }
    | null
  >
}

const PreSelectContactFormSection = ({
  title,
  text,
  image,
  actions,
}: Props) => {
  const [FormSection, setFormSection] = useState<ReactNode>()
  const [showContactForm, setShowContactForm] = useState<boolean>(false)

  const handleFormActions = (action: {
    type: 'FormAction'
    id: string
    title: string
    description: string
    form: ReactNode
  }) => {
    setFormSection(action.form)
    setShowContactForm(true)
  }

  return (
    <Section>
      <Container className={showContactForm ? 'hide' : 'show'}>
        <Row>
          <LeftColumn>
            <Title>{title}</Title>
            <Text>{text}</Text>
            <CardContainer>
              {actions?.map((action) =>
                // eslint-disable-next-line no-nested-ternary
                action && action.type === 'LinkAction' ? (
                  <ContactFormActionCard
                    key={action.id}
                    title={action.title}
                    description={action.description}
                    link={action.link}
                    onClick={() => ({})}
                  />
                ) : action ? (
                  <ContactFormActionCard
                    key={action.id}
                    title={action.title}
                    description={action.description}
                    onClick={() => handleFormActions(action)}
                  />
                ) : null,
              )}
            </CardContainer>
          </LeftColumn>
          <RightColumn>
            {image && (
              <StyledImage
                src={image}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                alt={image.alt!}
                width={554}
                height={412}
              />
            )}
          </RightColumn>
        </Row>
      </Container>
      <div
        style={
          showContactForm
            ? {
                position: 'relative',
                opacity: 1,
                transition: 'opacity .3s ease-in-out',
              }
            : { position: 'absolute', opacity: 0 }
        }
      >
        {FormSection}
      </div>
    </Section>
  )
}

export default PreSelectContactFormSection
