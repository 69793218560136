import RichText from '../components/RichText'
import { PreSelectContactFormSectionFragment } from '../graphql/preSelectContactFormSectionFragment.generated'
import PreSelectContactFormSection from '../sections/PreSelectContactFormSection'
import convertDatoImage from '../utils/convertDatoImage'
import ContactFormSectionBlock from './ContactFormSection'

interface Props {
  content: PreSelectContactFormSectionFragment
}

const PreSelectContactFormSectionBlock = ({
  content: { title, text, image, actions },
  ...others
}: Props) => {
  if (!title || !actions) {
    return null
  }

  return (
    <PreSelectContactFormSection
      title={title}
      text={<RichText text={text} />}
      image={convertDatoImage(image)}
      actions={actions
        .map((action) => {
          switch (action.__typename) {
            case 'PreSelectContactFormLinkActionRecord':
              return {
                id: action.id,
                type: 'LinkAction' as const,
                title: action.title ?? '',
                description: action.description ?? '',
                link: action.link
                  ? {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      href: action.link.url!,
                      target: action.link.openInNewTab
                        ? ('_blank' as const)
                        : undefined,
                    }
                  : undefined,
              }
            case 'PreSelectContactFormFormActionRecord':
              return {
                id: action.id,
                type: 'FormAction' as const,
                title: action.title ?? '',
                description: action.description ?? '',
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                form: <ContactFormSectionBlock content={action.form!} />,
              }
            default:
              if (process.env.NODE_ENV !== 'production') {
                // eslint-disable-next-line no-console
                console.warn(
                  'Received action which is not a Link or Form Action',
                  action,
                )
              }
              return null
          }
        })
        .filter((x) => x !== null)}
      {...others}
    />
  )
}

export default PreSelectContactFormSectionBlock
