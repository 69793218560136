/* eslint-disable react/jsx-no-useless-fragment */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import OpenNew from '../icons/OpenNew.svg'
import ArrowRight from '../icons/ArrowRight.svg'
import Link from './Link'

const Card = styled.div(
  ({ theme }) => css`
    display: flex;
    background: ${theme.colors.pageBackgroundColor};
    border: none;
    padding: ${theme.spacing.x3}px;
  `,
)

const SharedCardActionStyling = css`
  display: flex;
  flex-direction: row;
  border: none;
  align-items: center;
  cursor: pointer;

  svg {
    width: 40px;
    height: 40px;
  }
`

const StyledLink = styled(Link)(
  ({ theme }) => css`
    ${SharedCardActionStyling};
    gap: ${theme.spacing.x3}px;
    color: ${theme.colors.primaryBlue};

    &:hover {
      text-decoration: none;
    }
  `,
)

const StyledButton = styled.button(
  ({ theme }) => css`
    ${SharedCardActionStyling};
    gap: ${theme.spacing.x3}px;
    color: ${theme.colors.primaryBlue};
  `,
)

const LeftColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    width: 100%;
    color: ${theme.colors.neutral4};
    text-align: left;
  `,
)

const Title = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    color: ${theme.colors.primaryBlue};
    margin-bottom: ${theme.spacing.x2}px;
  `,
)

interface Props {
  title: string
  description: string
  link?: {
    href: string
    target?: string
  } | null
  onClick: () => void
}

const ContactCard = ({ title, description, link, onClick }: Props) => (
  <Card>
    {link && link.href && link.href !== '' ? (
      <StyledLink href={link?.href} target={link?.target}>
        <LeftColumn>
          <Title>{title}</Title>
          {description}
        </LeftColumn>
        <OpenNew />
      </StyledLink>
    ) : (
      <StyledButton type="button" onClick={onClick}>
        <LeftColumn>
          <Title>{title}</Title>
          {description}
        </LeftColumn>
        <ArrowRight />
      </StyledButton>
    )}
  </Card>
)

export default ContactCard
